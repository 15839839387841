import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { getApiDefaults } from "bkv-components/store_lib/actions/dataActions";
import axios from "axios";
import FileSaver from "file-saver";

const Div = styled.div`
    .title,
    h3 {
        border-bottom: 1px solid;
        margin-bottom: 2rem;
    }

    form {
        margin-bottom: 2rem;
    }

    button {
        max-width: 80%;
    }

    .buttons button {
        margin-right: 1rem;
    }

    .fields input {
        background-color: #e9ecef;
    }
`;

const Stage4 = () => {

    const [downloading, setDownloading] = useState(false);

    const dispatch = useDispatch();


    const states = useSelector((state) => state.data.states);
    const start_date = useSelector((state) => state.data.start_date);
    const end_date = useSelector((state) => state.data.end_date);
    const analysis_id = start_date + "_" + end_date;

    if (!start_date || !end_date) {

        return (
            <div className="d-flex row col-lg-9 col-sm-12 p-3">
                <div className="d-flex justify-content-between title">
                    <h4>Start date and end date must be defined</h4>
                </div>
            </div>
        );
    }


    // calculate output date as first day of next month after start_date
    let output_start_date = new Date(end_date);
    output_start_date.setMonth(output_start_date.getMonth() + 1);
    output_start_date.setDate(0);
    output_start_date = output_start_date.toISOString().slice(0,10);

    // calculate output_end_date as last day of the next month after output_start_day
    let output_end_date = new Date(output_start_date);
    output_end_date.setMonth(output_end_date.getMonth() + 3);
    output_end_date.setDate(-1);
    output_end_date = output_end_date.toISOString().slice(0,10);


    let today = new Date();
    // in today in YYYY-MM-DD format
    today = today.toISOString().slice(0,10);

    
    let manual_output_filename = today + "_monthly_gas_targets_operator_"+ output_start_date + "_" + output_end_date + ".csv";
    let automated_output_filename = "TargetNDT_" + output_start_date + "_" + output_end_date + ".csv";
    
    const ready = states.update_db === "finished" && states.generate_trends === "finished";

    const downloadGasTargets = async (type) => {

        setDownloading(true);
    
        try{

            const { headers, api_endpoint } = await getApiDefaults();

            let parameters = {
                analysis_id: analysis_id,
                start_date_output: output_start_date,
                end_date_output: output_end_date,
                type: type
            };

            let response = await axios.get(api_endpoint + "/download_final_target_output",
                { headers: headers, 
                    responseType: 'blob',
                    params: parameters }
            );

            if (type === "manual") {
                // Use FileSaver.js to prompt the save dialog
                FileSaver.saveAs(response.data, manual_output_filename);
            } else if (type === "automated") {
                // console.log('response.data:', response.data);
                // Use FileSaver.js to prompt the save dialog
                FileSaver.saveAs(response.data, automated_output_filename);
            } else {
                // raise error
                console.error('Error in source code: Invalid download type:', type);
            }

            } catch (error) {
                console.error('Error downloading CSV:', error);
            } finally {
                setDownloading(false);
            }
    }

    return (
         <Div className="d-flex row col-lg-9 col-sm-12 p-3">
            <div className="d-flex justify-content-between title">
                <h2>Final Result</h2>
                
            </div>

            <div>
            <div className="d-flex mb-3 fields row">
                <Field id="start_date" name="Output Start Date" value={output_start_date} />
                <Field id="end_date" name="Output End Date" value={output_end_date} />
                <Field id="output_filename" name="Manual Output Filename" value={manual_output_filename} />
            </div>

            <div className="d-flex mb-3 align-items-center">
            <Button variant="primary" 
                        disabled={!ready || downloading}
                        onClick={() => downloadGasTargets("manual")}
                >
                    Download Manual Gas Targets CSV
                </Button>
                {/* <span className="ms-3">
                Description
                </span> */}
            </div>


            <div className="d-flex mb-3 fields row">
                <Field id="output_filename_automated" name="Automated Output Filename" value={automated_output_filename} />
            </div>

            <div className="d-flex mb-3 align-items-center">
            <Button variant="primary" 
                        disabled={!ready || downloading}
                        onClick={() => downloadGasTargets("automated")}
                >
                    Download Automated Gas Targets CSV
                </Button>
                {/* <span className="ms-3">
                Description
                </span> */}
            </div>

            {downloading && <div className="d-flex mb-3 mt-3 align-items-center">
                <span className="ms-3">
                Downloading...
                </span>
            </div>
            }

            </div>
        </Div>
            
    );
};

export default Stage4;

const Field = ({ id, name, value }) => (
    <div className="form-group row mb-1">
        <label htmlFor={id} className="col-sm-3 col-form-label">
            {name}
        </label>
        <div className="col-sm-9">
            <input type="text" readOnly 
            // style={{}}
            className="form-control-plaintext text-center" id={id} value={value} />
        </div>
    </div>
);

